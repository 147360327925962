import React from 'react'
import { Container, Card } from 'Common'
import forkIcon from 'Static/icons/fork.svg'
import { Wrapper, Grid, Item, Content, Stats, Title } from './styles'

export const Projects = () =>
	 (
		<Wrapper as={Container} id="projects">
			<Title>10 Point Plan</Title>
			<Grid>
				<Item>
					<Card>
						<Content>
							<ol>
								<li>Transition to 100% Carbon Neutral Energy Generation by 2025</li>
								<li>Repair Austin’s ecosystem by planting 24 million trees by 2030</li>
								<li>Reduce our energy use and energy bills through upgrading every home to be a green home by 2030</li>
								<li>Build 40K Affordable green homes for Austin’s workforce by 2030</li>
								<li>Build fast affordable public transit and safe streets to reach 50% of all trips via transit, biking or carpool by 2030</li>
								<li>50% of all vehicles on austin’s roads must be electric by 2030 and 100% of trucks from large companies by 2030</li>
								<li>Reduce food related emissions and waste through food donations, supporting Local Farms and Community Gardens</li>
								<li>Reduce impervious cover by 10% and restore our water quality by ending chemical runoff</li>
								<li>Ensure a fair deal for works with no jobs paying less than $15 an hour and $1 raises until 2030. Specialized job training is free for all Austinites who want it</li>
								<li>Establish equity in improvements by investing in the most economically oppressed neighborhoods first and providing Universal Childcare</li>

							</ol>
							<em>This plan is still being discussed among our coalition. We welcome feedback. Please use the form below to get in touch to help us save the planet!</em>
						</Content>
						<Stats>
							{/* <div>
								<img src={starIcon} alt="stars" />
								<span>Element</span>
							</div>
							<div>
								<img src={forkIcon} alt="forks" />
								<span>Element</span>
							</div> */}
						</Stats>
					</Card>
				</Item>
			</Grid>
		</Wrapper>
	)

