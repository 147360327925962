import React from 'react'
import { Button, Input } from 'Common'
import { TextArea, Label } from './styles'

const ContactForm = () => (
	<form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field">
		<input type="hidden" name="form-name" value="contact" />
		<Label>Name: <Input required type="text" name="name" /></Label>
		<Label>Your Email: <Input required type="email" name="email" /></Label>

		<Label>Optional Telephone: <Input type="tel" name="tel" /></Label>

		<Label>Optional Comments/Thoughts/Brilliant Ideas: <TextArea name="message" />
		</Label>
		<Button type="submit">Submit</Button>
	</form>
)
export default ContactForm;