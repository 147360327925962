import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Header } from 'Theme'
import { Container, Button} from 'Common'
import windmills from 'Static/illustrations/windmills.svg'
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles'

export const Intro = () => (
	<Wrapper>
		<Header />
		<IntroWrapper as={Container}>
			<Details>
				<h1>Austin Needs a Green New Deal!</h1>
				<h4>Join us in the fight for an equitable sustainable future. While Washington is grid locked, Austinites need to band together and take the emergency seriously. </h4>
				<Button as={AnchorLink} href="#contact">
					Sign the Petition
				</Button>
				<Button as={AnchorLink} href="#contact">
					Get Involved
				</Button>
			</Details>
			<Thumbnail>
				<img src={windmills} alt="windmills" />
			</Thumbnail>
		</IntroWrapper>
	</Wrapper>
)
