import React from 'react'
import { Container } from 'Common'
import contact from 'Static/illustrations/contact.svg'
import { Wrapper, Details, Thumbnail } from './styles'
import ContactForm from './ContactForm'

export const Contact = () => (
	<Wrapper as={Container} id="contact">
		<h2>Join Us in the Fight for Our Future</h2>
		<h3>Sign up for more information or send us your ideas.</h3>
		<Details>
			<ContactForm />
		</Details>
	</Wrapper>
)
