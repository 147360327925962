import styled from 'styled-components'

export const Center = styled.div`
	text-align: left;

	h4 {
		font-weight: normal;
	}
`

export const Label = styled.label`
	font-size:1.2rem;
`
export const TextArea = styled.textarea`
	width: 100%;
	box-sizing: border-box;
	border: 2px solid #3BAA4A;
	padding: 0.8rem 1rem;
	border-radius: 7px;
	margin-bottom: 0.5rem;
	transition: 0.3s;
`
