import React, { useState }  from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Container, Button } from 'Common'
import { VictoryPie, VictoryTooltip } from 'victory';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles'


export const Skills = () => {
	const preTransition = [
		{ x: "Emissions Savings", y: 0 },
		{ x: "Transportation", y: 36},
		{ x: "Waste", y: 4 },
		{ x: "Industry", y: 7 },
		{ x: "Power Generation", y: 41 },
		{ x: "Stationary Combustion", y: 7 },
	];
	const postTransition = [
		{ x: "Emissions Savings", y: 69 },
		{ x: "Transportation", y: 16 },
		{ x: "Waste", y: 3 },
		{ x: "Industry", y: 5 },
		{ x: "Power Generation", y: 0 },
		{ x: "Stationary Combustion", y: 5 },
	];
	const [sampleData, setsampleData] = useState(preTransition);
	const [tracker, setTracker] = useState(true);
	const transition = () =>{
		if (!tracker){
			setsampleData(preTransition)
		}else{
			setsampleData(postTransition)
		}
		setTracker(!tracker);

	}


	return (
		<Wrapper id="about">
			<SkillsWrapper as={Container}>
				<Details>
					<h1>How the Green New Deal for Austin will radically reduce emissions</h1>
					<p>
						The chart shows the current emissions by sector here in Austin. By enacting the 10 Points we will reduce CO2 emmisions by ~70% by 2030
					</p>
					<ul>
						<li>Transition to sustainable energy while improving energy efficiency{tracker ? '' : '✓'}</li>
						<li>Reduce car dependence{tracker ? '' : '✓'}</li>
						<li>Electrify vehicles{tracker ? '' : '✓'}</li>
						<li>Green our buildings{tracker ? '' : '✓'}</li>
						<li>Plant a Texas sized number of trees{tracker ? '' : '✓'}</li>
					</ul>
					<Button onClick={transition}>
						{tracker ? 'Enact Green New Deal' : 'Restore the Pollution'}
					</Button>

				</Details>
				<Thumbnail>
					<VictoryPie
					// labelPosition="endAngle"
						animate={{ duration: 3000}}
						padding={{ left: 80, right: 80 }}
						height={600}
						width={650}
						padding={100}
						colorScale={["#06664A", "OrangeRed", "Goldenrod", "Maroon", "Red", "DarkOrange","Crimson" ]}
						// labelComponent={<VictoryLabel />}
						labels={({ datum }) => datum.y<=0 ? ' ':datum.x}
						// labelComponent={<VictoryTooltip />}
						data={sampleData}

						// events={[{
						// 	target: "data",
						// 	eventHandlers: {
						// 		onClick: () => {
						// 			return [{
						// 				target: ["data"],
						// 				mutation: (data) => {
						// 					switch (data.datum.x) {
						// 					case 'Transportation':
						// 						console.log(data.datum.x);
						// 						sampleData.find(obj=>obj.x=='Savings').y+=18;
						// 						sampleData.find(obj=>obj.x==data.datum.x).y-=18;
						// 						break;
						// 					case 'Waste':
						// 						console.log(data.datum.x);
						// 						sampleData.find(obj=>obj.x=='Savings').y+=2;
						// 						sampleData.find(obj=>obj.x==data.datum.x).y-=2;
						// 						break;
						// 					case 'Industry':
						// 						console.log(data.datum.x);
						// 						sampleData.find(obj=>obj.x=='Savings').y+=5;
						// 						sampleData.find(obj=>obj.x==data.datum.x).y-=5;
						// 						break;
						// 					case 'Power Generation':
						// 						sampleData.find(obj=>obj.x=='Savings').y+=41;
						// 						sampleData.find(obj=>obj.x==data.datum.x).y-=41;
						// 						console.log(data.datum.x);
						// 						break;
						// 					default:
						// 						console.log('Something went awry')
						// 					}
						// 					console.log(sampleData)
						// 					// TODO Add switch case
						// 				 return sampleData
						// 				}
						// 			}];
						// 		}
						// 	}
						// }]}
					/>
				</Thumbnail>
			</SkillsWrapper>
		</Wrapper>
	)};
