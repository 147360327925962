import styled from 'styled-components'

export const Wrapper = styled.div`
	padding-bottom: 4rem;
	background-image: url('../illustrations/overlay.svg');
	background-size: contain;
	background-position: right top;
	background-repeat: no-repeat;
	@media (max-width: 960px) {
		padding-bottom: 0;
	}
`

export const IntroWrapper = styled.div`
	padding: 4rem 0;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 960px) {
		flex-direction: column;
	}
`

export const Details = styled.div`
	flex: 1;

	@media (max-width: 960px) {
		width: 100%;
		margin-bottom: 2rem;
	}

	h1 {
		@font-face {
			font-family: "Kenyan";
			src: url("../kenyan.ttf");
		}
		background-image:linear-gradient( transparent 0%, transparent calc(80% - 8px), rgba(0, 255, 0, 0.35) calc(80% - 8px), rgba(0, 255, 0, 0.35) 50% );
		margin-bottom: 2rem;
		font-size: 3rem;
		font-family: "Kenyan";
		text-transform:uppercase;
		color: #212121;

		@media (max-width: 680px) {
			font-size: 3rem;
		}

		@-webkit-keyframes fadeInDown {
			from {
				opacity: 0;
				-webkit-transform: translate3d(0, -100%, 0);
				transform: translate3d(0, -100%, 0);
			}

			to {
				opacity: 1;
				-webkit-transform: translate3d(0, 0, 0);
				transform: translate3d(0, 0, 0);
			}
		}


	@keyframes fadeInDown {
		from {
			opacity: 0;
			-webkit-transform: translate3d(0, -100%, 0);
			transform: translate3d(0, -100%, 0);
		}

		to {
			opacity: 1;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}
	animation: fadeInDown ease 1s;
  -webkit-animation: fadeInDown ease 1s;
  -moz-animation: fadeInDown ease 1s;
  -o-animation: fadeInDown ease 1s;
  -ms-animation: fadeInDown ease 1s;
	}

	h4 {
		margin-bottom: 2.5rem;
		font-size: 32pt;
		font-weight: normal;
		color: #666;

		@media (max-width: 680px) {
			font-size: 26pt;
		}
	}
`

export const Thumbnail = styled.div`
	animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;


@keyframes fadeIn{
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
	flex: 1;

	@media (max-width: 960px) {
		width: 100%;

	}

	img {
		width: 100%;
	}
`
